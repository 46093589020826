.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  color: white;
}
.selected.hide {
  transform: translateY(-100px);
}
.frothlogo {
  display: flex;
  position: fixed;
  font-size: 56px;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}
.closebutton {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 56px;
  min-height: 8%;
  left: 50%;
  bottom: 0px;
  font-size: 36px;
  color: black;
  background-color: rgb(223, 223, 223, 0.9);
  transform: translateX(-50%);
  z-index: 4;
}
.closebutton.closed {
  transform: translate(-50%, 150px);
}
